import ViewContainer from "./ViewContainer"


const MyOrganizations = () => {
  return (
    <ViewContainer>
      <h1>My Organizations</h1>

    </ViewContainer>
  )
}

export default MyOrganizations